<template>
  <main class="sn-page">
    <div class="container py-5">
      <div class="row">
        <div class="md-down:col-12 lg:col-5 xl:col-3">
          <mega-card class="card-border card-round p-4">
            <div class="card-media">
              <mega-drop-zone
                @change="renderFile"
                :type="['image/jpeg', 'image/png']"
              >
                <mega-image
                  v-if="preview"
                  class="image bg-contain"
                  contain
                  ratio="16x9"
                  :src="preview"
                />
                <img v-else class="w-100" :src="sn.avatar" />
              </mega-drop-zone>
            </div>
          </mega-card>
        </div>

        <div class="md-down:col-12 lg:col-7 xl:col-9">
          <mega-card class="card-border card-round p-4">
            <div class="card-media" style="overflow: visible">
              <mega-input :label="$t('new_sn_name')" v-model="sn.name" />
              <mega-input :label="$t('new_sn_name_ru')" v-model="sn.name_ru" />
              <mega-textarea
                :label="$t('new_sn_description')"
                v-model="sn.description"
              />

              <mega-button class="btn btn-success w-100" @click="submit">{{
                $t("sn_save")
              }}</mega-button>
            </div>
          </mega-card>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  props: {
    id: {
      required: false,
      type: Number
    }
  },
  data() {
    return {
      snId: this.id == undefined ? this.$store.state.selectedSn.id : this.id,
      sn: null,
      avatar: null,
      preview: null
    };
  },
  async mounted() {
    console.log(this.$route);
    this.getSn();
  },
  methods: {
    async getSn() {
      this.sn = (
        await this.$api.v2.get("/sn/sn", {
          params: {
            sn_id: this.snId
          }
        })
      ).data;

      this.$navbar.name = `${this.$t("social_network")} #${this.snId} | ${
        this.sn.name
      }`;
    },
    async submit() {
      const formData = new FormData();
      formData.append("sn_id", this.snId);
      formData.append("name", this.sn.name);
      formData.append("name_ru", this.sn.name_ru);
      formData.append("description", this.sn.description);
      formData.append("avatar", this.avatar);

      this.$api.v2
        .put("/sn/sn", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(() => {
          this.$alert.success(this.$t("sn_updated"));
        })
        .catch(() => {
          this.$alert.danger(this.$t("sn_update_error"));
        });
    },
    renderFile(file) {
      if (!file) {
        this.$alert.danger(this.$t("err_file"));
        return;
      }

      this.avatar = file;

      const reader = new FileReader();
      reader.readAsDataURL(this.avatar);
      reader.onloadend = () => {
        this.preview = reader.result;
      };
    }
  }
};
</script>
